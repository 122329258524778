* {
  font-family: 'Hind Vadodara', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: $fontGeneralColor;

}

html {
  font-size: 57%;
  scroll-behavior: smooth;
}

body {
  background-color: $backgroundColor;
}

.container-fluid {
  width: 100%;
}

.head-container {
  width: 95%;
  margin: 0 auto;
  max-width: 1100px;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}

.w-100 {
  width: 100%;
}

p {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
}

/* SECTION */
.section-title {

  &.center {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

  }

  h1 {
    span {
      color: $white-000;
    }
  }

  h3 {
    position: relative;

    span {
      position: relative;
      font-size: 1.5rem;
      margin-left: 12px;
      color: $primaryColor;
      font-weight: 600;

      font,
      font font {
        font-size: 1.5rem;
        color: $primaryColor;
        font-weight: 600;
      }

      &::before {
        position: absolute;
        top: 0.2rem;
        left: -12px;
        content: " ";
        width: 2px;
        height: 1.8rem;
        background-color: $primaryColor;
      }
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1.8rem;
    color: $titleSectionColor;
    letter-spacing: 0.1rem;
    line-height: 4.3rem;
  }
}

.btn {
  display: flex;
  width: min-content;
  padding: 12px 24px;
  border: none;
  font-size: 14px;
  border-radius: 12px;
  font-weight: 600;
  color: $white-200;
  background-color: $azulOscuro;
  cursor: pointer;

  &:hover {
    background-color: lighten($azulOscuro, 10%)
  }

  .btn-arrow {
    background-image: url("../assets/svg/btn-arrow.svg");
    background-repeat: no-repeat;
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }


}

@keyframes fadeShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeShow {
  animation-name: fadeShow;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@media (min-width: 735px) {
  h1 {
    font-size: 4.7rem;
  }
}

@media (min-width: 915px) {
  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
  }
}

@media (min-width:750px) {
  html {
    font-size: 62.5%;
  }
}

@media (min-height: 750px) {
  html {
    font-size: 62.5%;
  }
}





/* DERECHOS */

.rights {
  background-color: #070512;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.2rem;
  }

  p:last-child {
    font-size: 1.3rem;
    color: $white-400;

    a {
      color: $white-400;
    }
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}




/* REVEAL */

.fade-in-down {
  opacity: 0;
  transform: translateY(50px);
}

.fade-in-down-show {
  transition: all 600ms ease-out;
  opacity: 1;
  transform: translateY(0px);
}