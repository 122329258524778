.fab-wpp__container {
    justify-content: center;
    align-items: center;
    display: flex;
    bottom: 2rem;
    right: 2rem;
    position: fixed;
    background-color: transparent;
    border: none;
}

.fab-wpp__bg {
    position: relative;
    z-index: 9;
    background-color: #2db742;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    box-shadow: 0 6px 8px 2px rgb(0 0 0 / 14%);
    cursor: pointer;
    transition: transform 400ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    .wpp-icon {
        background-image: url("../assets/img/wpp-icon.png");
        object-fit: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 2px;
        width: 100%;
        height: 100%;

        path {
            fill: $white-100;
        }
    }

    &:hover {
        transform: scale(1.2);
        transition: transform 300ms ease-in-out;
    }
}

.fab-wpp__container:hover .fab-wpp__message {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease-in-out;
}

.fab-wpp__message {
    color: white;
    position: absolute;
    left: -16rem;
    width: max-content;
    background-color: rgb(32, 116, 57);
    padding: 10px 5px 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    z-index: -9;
    opacity: 0;
    transform: translateX(45px);
    font-size: 1.6rem;
    transition: all 400ms ease-in-out;
}

.wpp__container {
    width: auto;
    height: 320px;
    position: fixed;
    bottom: 0;
    right: 2rem;
    left: 2rem;
    transform: translateY(100%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    transition: transform 400ms ease-in-out;

    &.show-chat {
        transition: transform 400ms ease-in-out;
        transform: translateY(0);
    }

    @media (min-width:400px) {
        left: auto;
        width: 320px;
        right: 2rem;
    }
}

.wpp__header {
    background-color: $chatBoxPrimary;
    width: 100%;
    height: 60px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.5rem;

    .wpp__avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    .wpp__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1rem;

        .wpp__agent-name {
            font-size: 1.7rem;
            line-height: 1.9rem;
            color: $white-000;
        }

        .wpp__agent-desc {
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: $white-100;
            border-top-left-radius: none;

            span {
                font-size: 1.3rem;
                opacity: 0.7;
            }
        }
    }

    .wpp__close-btn {
        background-color: transparent;
        font-size: 2rem;
        font-weight: 400;
        border: none;
        width: 30px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        svg {
            margin: 0;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.075);
        }
    }
}

.wpp__chat-container {
    background-color: #EEEEEE;
    flex-grow: 1;
    padding: 2rem;

    .wpp__chat-msg {
        display: inline-block;
        background-color: #FFFFFF;
        color: $black-100 !important;
        padding: 4px 8px;
        border-radius: 0 8px 8px 8px;
        margin-top: 4px;
        font-size: 1.4rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: translateY(4rem);

        font,
        font font {
            color: $black-100 !important;
        }

        &:not(:first-child) {
            margin-top: 1rem;
        }

        &.show {
            transition: all 500ms ease-in-out;
            transform: translateY(0);
            opacity: 1;
        }
    }

    .wpp__info-msg {
        margin-top: 1.4rem;
        color: gray;
        font-size: 1.5rem;
        line-height: 1.7rem;
    }


}

@keyframes rubberBand {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}


.rubberBand {
    animation-delay: 4s;

    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;

    -webkit-animation-duration: 1s;
    animation-duration: 1s;

    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;


}


form.wpp__actions-container {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.41);

    input {
        padding: 1rem;
        border: none;
        color: $black-100;
        flex-grow: 1;

        &:focus {
            outline: none;
        }
    }

    button {
        height: 4.9rem;
        aspect-ratio: 1/1;
        background-color: $chatBoxPrimary;
        border: none;
        padding: 1rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.9;
        }

        svg {

            path {
                fill: $white-100;
            }

            padding:1px;
            margin-left:0.2rem;
            height: 20px;
            width: 20px;
        }
    }
}