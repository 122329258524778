$azulOscuro: #0B081C;

$white-000: rgb(243, 243, 243);
$white-100: rgb(212, 212, 212);
$white-200: #BABABF;
$white-300: #B6B6B6;
$white-400: #8f8f8f;
$white-500: #8b8b8b;

$dorado-100: #C0B69D;
$dorado-200: #9b8f71;

$black-100:rgb(32, 32, 32);

/* GENERAL PAGE DETAILS */
$backgroundColor: $azulOscuro;

$fontGeneralColor: $white-200;
$titleSectionColor: $white-100;

$primaryColor: $dorado-100;

/* HEXAGONOS */
$primaryHexagonColor: #443E44;
$secondaryHexagonColor: #3C3949;

$titleHexagonColor: $white-100;
$textHexagonColor: $white-200;

$sectionPadding: 10rem 0;



/* CHAT_BOX */

// $chatBoxPrimary: #494855;


$chatBoxPrimary: #494855;

