/* GENERAL PAGE DETAILS */
/* HEXAGONOS */
/* CHAT_BOX */
* {
  font-family: "Hind Vadodara", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: #BABABF;
}

html {
  font-size: 57%;
  scroll-behavior: smooth;
}

body {
  background-color: #0B081C;
}

.container-fluid {
  width: 100%;
}

.head-container {
  width: 95%;
  margin: 0 auto;
  max-width: 1100px;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
}

.w-100 {
  width: 100%;
}

p {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
}

/* SECTION */
.section-title.center {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.section-title h1 span {
  color: rgb(243, 243, 243);
}
.section-title h3 {
  position: relative;
}
.section-title h3 span {
  position: relative;
  font-size: 1.5rem;
  margin-left: 12px;
  color: #C0B69D;
  font-weight: 600;
}
.section-title h3 span font,
.section-title h3 span font font {
  font-size: 1.5rem;
  color: #C0B69D;
  font-weight: 600;
}
.section-title h3 span::before {
  position: absolute;
  top: 0.2rem;
  left: -12px;
  content: " ";
  width: 2px;
  height: 1.8rem;
  background-color: #C0B69D;
}
.section-title h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1.8rem;
  color: rgb(212, 212, 212);
  letter-spacing: 0.1rem;
  line-height: 4.3rem;
}

.btn {
  display: flex;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 12px 24px;
  border: none;
  font-size: 14px;
  border-radius: 12px;
  font-weight: 600;
  color: #BABABF;
  background-color: #0B081C;
  cursor: pointer;
}
.btn:hover {
  background-color: #1b1344;
}
.btn .btn-arrow {
  background-image: url("../assets/svg/btn-arrow.svg");
  background-repeat: no-repeat;
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

@-webkit-keyframes fadeShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeShow {
  -webkit-animation-name: fadeShow;
          animation-name: fadeShow;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media (min-width: 735px) {
  h1 {
    font-size: 4.7rem;
  }
}
@media (min-width: 915px) {
  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
  }
}
@media (min-width: 750px) {
  html {
    font-size: 62.5%;
  }
}
@media (min-height: 750px) {
  html {
    font-size: 62.5%;
  }
}
/* DERECHOS */
.rights {
  background-color: #070512;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.rights p {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.2rem;
}
.rights p:last-child {
  font-size: 1.3rem;
  color: #8f8f8f;
}
.rights p:last-child a {
  color: #8f8f8f;
}
.rights p:not(:last-child) {
  margin-bottom: 1rem;
}

/* REVEAL */
.fade-in-down {
  opacity: 0;
  transform: translateY(50px);
}

.fade-in-down-show {
  transition: all 600ms ease-out;
  opacity: 1;
  transform: translateY(0px);
}

header {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  min-height: 450px;
  padding: 0 clamp(10px, 5%, 700px);
}

.header__vika-logo {
  min-height: 50px;
  height: 5vw;
  max-height: 70px;
  transition: transform 350ms ease-in-out;
  cursor: pointer;
}
.header__vika-logo:hover {
  transform: scale(1.1);
  transition: transform 250ms ease-in-out;
}

.header__main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
}

.header__nav-wrapper {
  padding: 0 30px;
  position: fixed;
  width: 100vw;
  left: 0;
  z-index: 9;
  background-color: rgba(13, 10, 32, 0.8823529412);
  border-bottom: 1px solid #8b8b8b;
  top: 0;
}

.header__nav-wrapper.open-menu::after {
  content: " ";
  background-color: rgba(0, 0, 0, 0.178);
  opacity: 0;
  transition: opacity 2000ms ease-in-out;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.header__nav-container {
  padding: 20px 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

header nav {
  justify-content: space-between;
}
header nav ul {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  flex-grow: 1;
  max-width: 400px;
}
header nav ul li {
  font-size: 1.7rem;
  font-weight: 600;
  list-style: none;
  transition: all 400ms ease-in-out;
}
header nav ul li:not(.selected):hover a {
  color: rgb(212, 212, 212);
}
header nav ul li a {
  position: relative;
  text-decoration: none;
  color: #B6B6B6;
}
header nav ul li a::before {
  position: absolute;
  content: "";
  width: calc(100% + 6px);
  right: -3px;
  height: 3px;
  bottom: -6px;
  background-color: rgb(212, 212, 212);
  opacity: 0;
  transition: all 400ms ease-in-out;
}
header nav ul li:not(:last-child) {
  margin-right: 20px;
}
header nav ul.ul-separator {
  position: relative;
  flex-grow: 0;
}
header nav ul.ul-separator li:not(:last-child) {
  margin-right: 25px;
}
header nav ul.ul-separator::after {
  position: absolute;
  top: calc(25% - 1px);
  right: 50%;
  z-index: 9;
  content: " ";
  width: 2px;
  height: 1.7rem;
  background-color: #8f8f8f;
}
header nav .header__section-menu {
  margin-right: 4vw;
}
header nav .header__section-menu .selected {
  transform: translateY(-6px);
  transition: all 200ms ease-out;
}
header nav .header__section-menu .selected a::before {
  transform: translateY(0);
  transition: all 300ms ease-in-out;
  opacity: 1;
}
header nav .header__section-menu .selected a {
  color: rgb(212, 212, 212);
}

.header__nav-wrapper-fixed {
  display: none;
}

nav span:hover,
nav ul li:hover {
  opacity: 1;
  color: rgb(243, 243, 243) !important;
  transition: all 200ms ease-in-out;
}

.header__translate-menu-collapsed li,
.header__translate-menu li {
  opacity: 0.4;
  cursor: pointer;
}
.header__translate-menu-collapsed .selected,
.header__translate-menu .selected {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.header__menu-icon {
  position: relative;
  z-index: 10;
  stroke: white;
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  height: 2.8rem;
  width: 2.8rem;
  margin-right: 10px;
  transition: transform 300ms ease-out;
  cursor: pointer;
}
.header__menu-icon line:nth-child(1) {
  transform-origin: center;
  transition: transform 300ms ease-out;
}
.header__menu-icon line:nth-child(2) {
  transform-origin: 5px 9px;
  transition: transform 300ms ease-out;
}
.header__menu-icon line:nth-child(3) {
  transform-origin: 5px 15px;
  transition: transform 300ms ease-out;
}
.header__menu-icon.open-menu line {
  transition: transform 300ms ease-out;
}
.header__menu-icon.open-menu line:nth-child(1) {
  transform: scale(0);
}
.header__menu-icon.open-menu line:nth-child(2) {
  transform: rotateZ(45deg);
}
.header__menu-icon.open-menu line:nth-child(3) {
  transform: rotateZ(-45deg);
}

.header__nav-collapsed {
  display: static;
}

.header__nav-modal {
  z-index: 9;
  position: absolute;
  display: flex;
  top: 76px;
  right: 0;
  background-color: rgba(13, 10, 32, 0.8823529412);
  width: 100%;
  min-width: 320px;
  height: calc(100vh - 75px);
  transform: translateX(100%);
  transition: transform 400ms ease;
  padding-bottom: 20%;
  justify-content: space-evenly;
}
.header__nav-modal.open-menu {
  transform: translateX(0);
  transition: transform 400ms ease;
}
.header__nav-modal .header__section-menu-collapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  flex-grow: 0;
}
.header__nav-modal .header__section-menu-collapsed li {
  font-size: 3.3rem;
  margin-top: 1rem;
}
.header__nav-modal .header__section-menu-collapsed li.selected a span {
  color: #b89d58;
}
.header__nav-modal .header__section-menu-collapsed li:hover a span {
  color: #C0B69D !important;
}
.header__nav-modal .header__translate-menu-collapsed {
  position: relative;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin-top: 6rem;
}
.header__nav-modal .header__translate-menu-collapsed li {
  font-size: 2.4rem;
}

.header__nav-fulled {
  display: none;
}

@media (min-width: 840px) {
  .header__nav-container {
    position: static;
    height: 110px;
  }
  .header__nav-collapsed {
    display: none;
  }
  .header__nav-fulled {
    display: flex;
  }
  .header__vika-logo {
    min-height: 60px;
  }
  .header__nav-wrapper {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
    position: relative;
    transition: all 1s ease-in-out;
    width: auto;
  }
  .header__nav-wrapper-fixed {
    display: flex;
    background-color: rgba(13, 10, 32, 0.8823529412);
    border-bottom: 1px solid #8b8b8b;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    z-index: 10;
    transition: transform 600ms ease-in-out;
    transform: translateY(-100%);
  }
  .header__nav-wrapper-fixed .header__vika-logo {
    transform: scale(0.8);
  }
  .header__nav-wrapper-fixed .header__nav-container {
    position: static;
    max-width: 1100px;
    width: 90%;
    height: 75px;
  }
  .header__nav-wrapper-fixed.show-fixed-nav {
    transform: translateY(0);
  }
}
@media (min-width: 940px) {
  header {
    padding: 0 clamp(10px, 10%, 700px);
  }
}
@-webkit-keyframes timeBarAnim {
  from {
    height: 0;
    opacity: 0.2;
  }
  to {
    height: 100%;
    opacity: 0.9;
  }
}
@keyframes timeBarAnim {
  from {
    height: 0;
    opacity: 0.2;
  }
  to {
    height: 100%;
    opacity: 0.9;
  }
}
@-webkit-keyframes imgZoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes imgZoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.carrousel__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
}

.carrousel_timebar {
  position: absolute;
  z-index: 8;
  bottom: 0;
  left: 0;
  height: 0;
  width: 5px;
  background-color: rgb(212, 212, 212);
}
.carrousel_timebar.show-bar {
  -webkit-animation-name: timeBarAnim;
          animation-name: timeBarAnim;
  -webkit-animation-duration: 8.5s;
          animation-duration: 8.5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.carrousel_img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.carrousel_img-container.zoom {
  -webkit-animation-name: imgZoom;
          animation-name: imgZoom;
  -webkit-animation-duration: 8.5s;
          animation-duration: 8.5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.carrousel_img-container .carrousel__img {
  width: 100%;
  height: 100%;
  z-index: -1;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
}
.carrousel_img-container .carrousel__img.show-img {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.carrousel__title {
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 2rem;
  color: rgb(212, 212, 212);
  width: 100%;
}

.carrousel__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 25%;
  width: 100%;
}
.carrousel__info .carrousel__count {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  letter-spacing: 0.8rem;
  color: #8f8f8f;
  width: 100%;
}
.carrousel__info .carrousel__count span {
  font-size: 3rem;
  line-height: 3rem;
  color: rgb(212, 212, 212);
}
.carrousel__info .carrousel__text-wrapper {
  overflow: hidden;
  background-color: transparent;
  width: 100%;
  transform: translateX(20%);
  display: flex;
  flex-direction: column;
}
.carrousel__info .carrousel__text-wrapper .carrousel__count,
.carrousel__info .carrousel__text-wrapper .carrousel__title,
.carrousel__info .carrousel__text-wrapper .carrousel__description,
.carrousel__info .carrousel__text-wrapper .carrousel_cta {
  transform: translateX(-100%);
}
.carrousel__info .carrousel__text-wrapper.show {
  transform: translateX(0);
  transition: transform 600ms ease-in-out;
}
.carrousel__info .carrousel__text-wrapper.show .show {
  transform: translateX(0);
  transition: transform 600ms ease-in-out;
}
.carrousel__info .carrousel__description {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.carrousel_buttons-container {
  position: absolute;
  bottom: 15%;
}

.carrousel__button {
  top: 0;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  min-width: 100px;
  overflow: hidden;
  cursor: pointer;
}
.carrousel__button p {
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  font-weight: 600;
}
.carrousel__button::after {
  content: " ";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #8f8f8f;
}
.carrousel__button:hover::after {
  opacity: 0.4;
  transition: opacity 200ms ease-in-out;
}

.carrousel-previous {
  left: 0;
}

.carrousel-next {
  right: 0;
}

@media (min-width: 640px) {
  .carrousel__info {
    width: 90%;
  }
}
@media (min-width: 915px) {
  .carrousel_buttons-container {
    position: static;
  }
  .carrousel__info {
    margin-bottom: 15%;
  }
  .carrousel__button {
    position: absolute;
    top: 0;
    border: none;
    background-color: transparent;
    margin-right: 0;
    height: 100%;
    width: 10%;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carrousel__button p {
    transform: rotate(-90deg);
  }
  .carrousel__title {
    font-size: 5rem;
    line-height: 5rem;
  }
  .carrousel-previous {
    left: 0;
  }
  .carrousel-next {
    right: 0;
  }
}
@media (min-width: 1160px) {
  .carrousel__title {
    font-size: 6rem;
    line-height: 6rem;
  }
}
.fab-wpp__container {
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 2rem;
  right: 2rem;
  position: fixed;
  background-color: transparent;
  border: none;
}

.fab-wpp__bg {
  position: relative;
  z-index: 9;
  background-color: #2db742;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.14);
  cursor: pointer;
  transition: transform 400ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fab-wpp__bg .wpp-icon {
  background-image: url("../assets/img/wpp-icon.png");
  -o-object-fit: contain;
     object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 2px;
  width: 100%;
  height: 100%;
}
.fab-wpp__bg .wpp-icon path {
  fill: rgb(212, 212, 212);
}
.fab-wpp__bg:hover {
  transform: scale(1.2);
  transition: transform 300ms ease-in-out;
}

.fab-wpp__container:hover .fab-wpp__message {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms ease-in-out;
}

.fab-wpp__message {
  color: white;
  position: absolute;
  left: -16rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: rgb(32, 116, 57);
  padding: 10px 5px 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  z-index: -9;
  opacity: 0;
  transform: translateX(45px);
  font-size: 1.6rem;
  transition: all 400ms ease-in-out;
}

.wpp__container {
  width: auto;
  height: 320px;
  position: fixed;
  bottom: 0;
  right: 2rem;
  left: 2rem;
  transform: translateY(100%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: transform 400ms ease-in-out;
}
.wpp__container.show-chat {
  transition: transform 400ms ease-in-out;
  transform: translateY(0);
}
@media (min-width: 400px) {
  .wpp__container {
    left: auto;
    width: 320px;
    right: 2rem;
  }
}

.wpp__header {
  background-color: #494855;
  width: 100%;
  height: 60px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.5rem;
}
.wpp__header .wpp__avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.wpp__header .wpp__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
.wpp__header .wpp__info .wpp__agent-name {
  font-size: 1.7rem;
  line-height: 1.9rem;
  color: rgb(243, 243, 243);
}
.wpp__header .wpp__info .wpp__agent-desc {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: rgb(212, 212, 212);
  border-top-left-radius: none;
}
.wpp__header .wpp__info .wpp__agent-desc span {
  font-size: 1.3rem;
  opacity: 0.7;
}
.wpp__header .wpp__close-btn {
  background-color: transparent;
  font-size: 2rem;
  font-weight: 400;
  border: none;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.wpp__header .wpp__close-btn svg {
  margin: 0;
}
.wpp__header .wpp__close-btn:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

.wpp__chat-container {
  background-color: #EEEEEE;
  flex-grow: 1;
  padding: 2rem;
}
.wpp__chat-container .wpp__chat-msg {
  display: inline-block;
  background-color: #FFFFFF;
  color: rgb(32, 32, 32) !important;
  padding: 4px 8px;
  border-radius: 0 8px 8px 8px;
  margin-top: 4px;
  font-size: 1.4rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(4rem);
}
.wpp__chat-container .wpp__chat-msg font,
.wpp__chat-container .wpp__chat-msg font font {
  color: rgb(32, 32, 32) !important;
}
.wpp__chat-container .wpp__chat-msg:not(:first-child) {
  margin-top: 1rem;
}
.wpp__chat-container .wpp__chat-msg.show {
  transition: all 500ms ease-in-out;
  transform: translateY(0);
  opacity: 1;
}
.wpp__chat-container .wpp__info-msg {
  margin-top: 1.4rem;
  color: gray;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

@-webkit-keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scaleX(1);
  }
}
.rubberBand {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

form.wpp__actions-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.41);
}
form.wpp__actions-container input {
  padding: 1rem;
  border: none;
  color: rgb(32, 32, 32);
  flex-grow: 1;
}
form.wpp__actions-container input:focus {
  outline: none;
}
form.wpp__actions-container button {
  height: 4.9rem;
  aspect-ratio: 1/1;
  background-color: #494855;
  border: none;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
form.wpp__actions-container button:hover {
  opacity: 0.9;
}
form.wpp__actions-container button svg {
  padding: 1px;
  margin-left: 0.2rem;
  height: 20px;
  width: 20px;
}
form.wpp__actions-container button svg path {
  fill: rgb(212, 212, 212);
}

.loader {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contacto__section {
  padding-top: 8rem;
  padding-bottom: 3rem;
}

.contacto__grid {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  row-gap: 40px;
  margin: 3rem 0;
}

.contacto__info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  grid-row: 3/4;
}
.contacto__info-container h3 {
  font-size: 2.3rem;
  margin-bottom: 1.5rem;
}
.contacto__info-container h3 span {
  color: rgb(243, 243, 243);
}
.contacto__info-container h3,
.contacto__info-container .contacto__info-horarios,
.contacto__info-container .contacto__info-encontranos {
  text-align: left;
  width: 100%;
  min-width: 240px;
}
.contacto__info-container h3 div,
.contacto__info-container .contacto__info-horarios div,
.contacto__info-container .contacto__info-encontranos div {
  line-height: 2.5rem;
  font-size: 1.5rem;
}
.contacto__info-container h3 div h4,
.contacto__info-container .contacto__info-horarios div h4,
.contacto__info-container .contacto__info-encontranos div h4 {
  display: inline;
  font-weight: 800;
  margin-right: 20px;
  color: rgb(212, 212, 212);
}
.contacto__info-container h3 div:not(:last-child),
.contacto__info-container .contacto__info-horarios div:not(:last-child),
.contacto__info-container .contacto__info-encontranos div:not(:last-child) {
  margin-bottom: 10px;
}

.loading-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-button p {
  margin-left: 8px;
}

.contacto__vika-logo img {
  margin-top: 4rem;
  margin-right: 0%;
  width: 15rem;
}

.contacto__form {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: none;
  margin-left: auto;
  grid-row: 1/2;
  height: 100%;
}
.contacto__form div {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.contacto__form div.w-100 {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  flex-grow: 1;
}
.contacto__form .contacto__form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contacto__form input {
  width: 100%;
  height: 3.7rem;
  background-color: #242133;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.41);
  border: none;
  margin: 8px 0 0 0;
  padding: 6px 10px;
  border-radius: 12px;
}
.contacto__form input:focus {
  outline: 1px solid rgb(90, 90, 90);
}
.contacto__form input:nth-child(2n-1) {
  margin-right: 10px;
}
.contacto__form .w-50 {
  width: 47%;
}
.contacto__form .contacto__textarea-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 8px;
}
.contacto__form .contacto__textarea {
  width: 100%;
  border: none;
  padding: 6px 10px;
  height: 100%;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.103);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.41);
  flex-grow: 2;
}
.contacto__form .contacto__textarea:focus {
  outline: 1px solid rgb(90, 90, 90);
}
.contacto__form .contacto__form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacto__form .contacto__form-response {
  flex-grow: 1;
  text-align: center;
  color: green;
}
.contacto__form .contacto__form-response p {
  flex-grow: 1;
  text-align: center;
}
.contacto__form .contacto__succes-msg {
  color: #307f30;
}
.contacto__form .contacto__form-btn {
  background-color: rgba(255, 255, 255, 0.103);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.41);
  margin: 8px 0;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  align-self: flex-end;
}
.contacto__form .contacto__form-btn:hover {
  opacity: 70%;
}

.form-error {
  font-size: 1.3rem;
  color: #cd4242;
  position: relative;
  top: -1.3rem;
  left: 1rem;
  z-index: -1;
  opacity: 0;
}
.form-error.show {
  transform: translateY(1.3rem);
  opacity: 1;
  transition: all 300ms ease;
}

.contacto__info-encontranos {
  margin-top: 40px;
}

@media (min-width: 580px) {
  .contacto__info-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .contacto__info-encontranos {
    margin-top: 0px;
  }
  .contacto__form {
    grid-row: 1/2;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  .contacto__grid {
    grid-template-columns: 1fr 1fr;
  }
  .contacto__info-container h3,
.contacto__info-container .contacto__info-horarios,
.contacto__info-container .contacto__info-encontranos {
    width: 50%;
  }
  .contacto__vika-logo img {
    margin-right: 30%;
    margin-top: 0;
    width: 16rem;
  }
}
@media (min-width: 850px) {
  .contacto__vika-logo img {
    margin-right: 0%;
    width: 18rem;
  }
  .contacto__form {
    max-width: 350px;
    grid-column: 3/4;
  }
  .contacto__grid {
    grid-template-columns: 1fr -webkit-min-content 1fr;
    grid-template-columns: 1fr min-content 1fr;
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
  .contacto__info-container {
    flex-direction: column;
  }
  .contacto__info-container {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .contacto__info-encontranos {
    margin-top: 40px;
  }
  .contacto__info-container h3,
.contacto__info-container .contacto__info-horarios,
.contacto__info-container .contacto__info-encontranos {
    width: 100%;
  }
}
.familia__section {
  background-color: #070512;
  padding: 10rem 0;
  margin-top: 8rem;
}

.familia_grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 30px;
  margin-top: 10px;
}

.familia-card__container {
  position: relative;
  max-width: 490px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.familia-card__container:nth-child(even) {
  margin-left: auto;
}
.familia-card__container .familia-card__fondo-info {
  display: flex;
  align-items: center;
  padding-right: 8rem;
  font-size: 2rem;
}
.familia-card__container .familia-card__fondo-info .familia-card__fondo-number {
  font-size: 3.5rem;
  vertical-align: bottom;
}
.familia-card__container .familia-card__fondo-info .familia-card__fondo-title {
  display: flex;
  align-items: center;
  line-height: 2.5rem;
  margin-left: 1rem;
  height: 100%;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}
.familia-card__container .familia-card__fondo-info .familia-card__fondo-title span {
  margin-bottom: 0.5rem;
}
.familia-card__container .familia-card__circular-graph {
  background-color: #C0B69D;
  width: 100%;
  height: 3px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.familia-card__container .familia-card__circular-graph div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -2px;
}
.familia-card__container .familia-card__circular-graph p {
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  margin-left: 6px;
  margin-bottom: 2px;
  height: 100%;
  font-weight: 600;
  font-size: 2rem;
}
.familia-card__container .familia-card__fondo-description {
  margin-top: 1rem;
  padding-right: 80px;
}

@media (min-width: 500px) {
  .familia-card__fondo-info {
    font-size: 2.5rem;
  }
  .familia-card__fondo-info .familia-card__fondo-number {
    font-size: 4rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .familia_grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}
.productos__section {
  padding: 10rem 0;
  padding-bottom: 3rem;
}
.productos__section h2 {
  font-size: 2.4em;
}

.productos__grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 4rem;
}

.productos__card-container {
  background-color: rgba(255, 255, 255, 0.103);
  padding: 25px 25px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.41);
}

.productos_card-icon svg path {
  fill: #9b8f71;
}

.productos__card-info {
  width: 80%;
  padding-left: 2rem;
}
.productos__card-info h3 {
  font-size: 2.4rem;
  margin-bottom: 1.8rem;
  line-height: 2.8rem;
}
@media (min-width: 815px) {
  .productos__grid {
    grid-template-columns: 1fr 1fr;
  }
}
.quienes-somos__section {
  padding: 10rem 0;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 6rem;
}
.quienes-somos__section .quienes-somos__info p:not(:last-child) {
  margin-bottom: 1.8rem;
}
.quienes-somos__section .quienes-somos__metrics {
  position: relative;
}
.quienes-somos__section .quienes-somos__metrics .quienes-somos__metrics-container {
  position: relative;
  width: 280px;
  height: 350px;
  margin: 0 auto;
}
.quienes-somos__section .quienes-somos__metrics .hex-container {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  position: relative;
  transition: all 450ms linear;
}
.quienes-somos__section .quienes-somos__metrics .hex-container .hex-info {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15% 0 0;
}
.quienes-somos__section .quienes-somos__metrics .hex-container .hex-info h1 {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  line-height: 3rem;
  color: rgb(212, 212, 212);
}
.quienes-somos__section .quienes-somos__metrics .hex-container .hex-info p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4rem;
  color: #BABABF;
}
.quienes-somos__section .quienes-somos__metrics .hex-container .hex {
  height: 100%;
  position: absolute;
  z-index: 1;
}
.quienes-somos__section .quienes-somos__metrics .hex-container .hex polygon {
  fill: #443E44;
}
.quienes-somos__section .quienes-somos__metrics .hex1,
.quienes-somos__section .quienes-somos__metrics .hex2,
.quienes-somos__section .quienes-somos__metrics .hex3 {
  transition: transform 450ms linear;
}
.quienes-somos__section .quienes-somos__metrics .hex-1.hex-hovered {
  transform: translate(20px, 20px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-2.hex-hovered {
  transform: translate(-10px, 10px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-3.hex-hovered {
  transform: translate(10px, -30px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-1.hex-not-hovered {
  transform: scale(0.65) translate(20px, 20px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-2.hex-not-hovered {
  transform: scale(0.6) translate(20px, -20px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-3.hex-not-hovered {
  transform: scale(0.72) translate(-20px, 0px);
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-hovered .hex-container {
  transform: scale(1.2);
  transform-origin: center;
  transition: all 300ms ease-in-out;
}
.quienes-somos__section .quienes-somos__metrics .hex-not-hovered {
  opacity: 0.5;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-hovered,
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-hovered {
  opacity: 0.5;
  transition: all 450ms linear;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-not-hovered,
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-not-hovered {
  opacity: 0.2;
  transition: all 450ms linear;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-hovered {
  opacity: 0.6;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-hovered .hex-container {
  transform: scale(0.8) translate(-20px, 20px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-not-hovered {
  opacity: 0.2;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-1.hex-not-hovered .hex-container {
  transform: scale(0.5) translate(-10px, -20px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-hovered {
  opacity: 0.6;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-hovered .hex-container {
  transform: scale(0.8) translate(20px, 20px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-not-hovered {
  opacity: 0.2;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-2.hex-not-hovered .hex-container {
  transform: scale(0.5) translate(30px, -40px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-3.hex-hovered {
  opacity: 0.6;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-3.hex-hovered .hex-container {
  transform: scale(0.8) translate(-20px, 20px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-3.hex-not-hovered {
  opacity: 0.2;
}
.quienes-somos__section .quienes-somos__metrics .hex-sec-3.hex-not-hovered .hex-container {
  transform: scale(0.5) translate(30px, -40px);
  transition: all 300ms ease-in-out;
  transform-origin: center;
}

@media (min-width: 735px) {
  .quienes-somos__section {
    grid-template-columns: 1fr 1fr;
  }
}/*# sourceMappingURL=styles.css.map */