.familia__section {
    background-color: #070512;
    // background-color: #dadada;
    padding: $sectionPadding;
    margin-top: 8rem;
}

.familia_grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    gap: 30px;
    margin-top: 10px;
}

.familia-card__container {
    position: relative;
    max-width: 490px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &:nth-child(even) {
        margin-left: auto;
    }

    .familia-card__fondo-info {
        display: flex;
        align-items: center;
        padding-right: 8rem;
        font-size: 2rem;

        .familia-card__fondo-number {
            font-size: 3.5rem;
            vertical-align: bottom;
        }

        .familia-card__fondo-title {
            display: flex;
            align-items: center;
            line-height: 2.5rem;
            margin-left: 1rem;
            height: 100%;
            padding-top: 2.5rem;
            padding-bottom: 1.5rem;

            span {
                margin-bottom: 0.5rem;
            }
        }
    }

    .familia-card__circular-graph {
        background-color: $dorado-100;
        width: 100%;
        height: 3px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -2px;
        }

        p {
            display: flex;
            position: absolute;
            text-align: center;
            align-items: center;
            margin-left: 6px;
            margin-bottom: 2px;
            height: 100%;
            font-weight: 600;
            font-size: 2rem;
        }
    }

    .familia-card__fondo-description {
        margin-top: 1rem;
        padding-right: 80px;

    }
}

@media(min-width:500px) {


    .familia-card__fondo-info {
        font-size: 2.5rem;

        .familia-card__fondo-number {
            font-size: 4rem;
            width: max-content;
        }
    }

    .familia_grid {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}