.productos__section {
    padding: $sectionPadding;
    padding-bottom: 3rem;

    h2 {
        font-size: 2.4em;
    }
}

.productos__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 4rem;
}

.productos__card-container {
    background-color: rgba(255, 255, 255, 0.103);
    padding: 25px 25px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.41);
}

.productos_card-icon {
    svg path {
         fill: $dorado-200;
    }
}

.productos__card-info {
    width: 80%;
    padding-left: 2rem;

    h3 {
        font-size: 2.4rem;
        margin-bottom: 1.8rem;
        line-height: 2.8rem;
        // color: $dorado-100;
    }

    p {
        // color: $white-400;
    }
}


@media (min-width: 815px) {
    .productos__grid {
        grid-template-columns: 1fr 1fr;
    }
}