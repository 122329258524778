.quienes-somos__section {
    padding: $sectionPadding;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 6rem;

    .quienes-somos__info {

        p:not(:last-child) {
            margin-bottom: 1.8rem;
        }
    }

    .quienes-somos__metrics {
        position: relative;

        .quienes-somos__metrics-container {
            position: relative;
            width: 280px;
            height: 350px;
            margin: 0 auto;
        }

        .hex-container {
            width: min-content;
            position: relative;
            transition: all 450ms linear;

            .hex-info {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 15% 0 0;

                h1 {
                    font-size: 3rem;
                    font-weight: 600;
                    text-align: center;
                    line-height: 3rem;
                    color: $titleHexagonColor;
                }

                p {
                    font-size: 1.5rem;
                    text-align: center;
                    line-height: 1.4rem;
                    color: $textHexagonColor;
                }

            }

            .hex {
                height: 100%;
                position: absolute;
                z-index: 1;

                polygon {
                    fill: $primaryHexagonColor;
                }
            }
        }

        .hex1,
        .hex2,
        .hex3 {
            transition: transform 450ms linear;
        }

        .hex-1.hex-hovered {
            transform: translate(20px, 20px);
            transition: all 300ms ease-in-out;
        }

        .hex-2.hex-hovered {
            transform: translate(-10px, 10px);
            transition: all 300ms ease-in-out;
        }

        .hex-3.hex-hovered {
            transform: translate(10px, -30px);
            transition: all 300ms ease-in-out;
        }

        .hex-1.hex-not-hovered {
            transform: scale(0.65) translate(20px, 20px);
            transition: all 300ms ease-in-out;
        }

        .hex-2.hex-not-hovered {
            transform: scale(0.6) translate(20px, -20px);
            transition: all 300ms ease-in-out;
        }

        .hex-3.hex-not-hovered {
            transform: scale(0.72) translate(-20px, 0px);
            transition: all 300ms ease-in-out;
        }

        .hex-hovered {
            .hex-container {
                transform: scale(1.2);
                transform-origin: center;
                transition: all 300ms ease-in-out;
            }
        }

        .hex-not-hovered {
            opacity: 0.5;
        }


        .hex-sec-1.hex-hovered,
        .hex-sec-2.hex-hovered {
            opacity: 0.5;
            transition: all 450ms linear;
        }

        .hex-sec-1.hex-not-hovered,
        .hex-sec-2.hex-not-hovered {
            opacity: 0.2;
            transition: all 450ms linear;
        }

        .hex-sec-1 {
            &.hex-hovered {
                opacity: 0.6;

                .hex-container {
                    transform: scale(0.8) translate(-20px, 20px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }

            &.hex-not-hovered {
                opacity: 0.2;

                .hex-container {
                    transform: scale(0.5) translate(-10px, -20px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }
        }

        .hex-sec-2 {
            &.hex-hovered {
                opacity: 0.6;

                .hex-container {
                    transform: scale(0.8) translate(20px, 20px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }

            &.hex-not-hovered {
                opacity: 0.2;

                .hex-container {
                    transform: scale(0.5) translate(30px, -40px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }
        }

        .hex-sec-3 {
            &.hex-hovered {
                opacity: 0.6;

                .hex-container {
                    transform: scale(0.8) translate(-20px, 20px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }

            &.hex-not-hovered {
                opacity: 0.2;

                .hex-container {
                    transform: scale(0.5) translate(30px, -40px);
                    transition: all 300ms ease-in-out;
                    transform-origin: center;
                }
            }
        }


    }
}

@media (min-width:735px) {
    .quienes-somos__section {
        grid-template-columns: 1fr 1fr;
    }
}