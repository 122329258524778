$headerBG: #0d0a20e1;

header {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    min-height: 450px;
    padding: 0 clamp(10px, 5%, 700px);
}

.header__vika-logo {
    min-height: 50px;
    height: 5vw;
    max-height: 70px;
    transition: transform 350ms ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: transform 250ms ease-in-out;
    }
}

.header__main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 600px;
}

.header__nav-wrapper {
    padding: 0 30px;
    position: fixed;
    width: 100vw;
    left: 0;
    z-index: 9;
    background-color: $headerBG;
    border-bottom: 1px solid $white-500;
    top: 0;
}

.header__nav-wrapper.open-menu {
    &::after {
        content: " ";
        background-color: rgba(0, 0, 0, 0.178);
        opacity: 0;
        transition: opacity 2000ms ease-in-out;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}

.header__nav-container {
    padding: 20px 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
}


header {
    nav {
        justify-content: space-between;

        ul {
            display: flex;
            position: relative;
            justify-content: space-evenly;
            flex-grow: 1;
            max-width: 400px;

            li {
                font-size: 1.7rem;
                font-weight: 600;
                list-style: none;
                transition: all 400ms ease-in-out;

                &:not(.selected):hover a {
                    color: $white-100;
                }

                a {
                    position: relative;
                    text-decoration: none;
                    color: $white-300;
                }

                a::before {
                    position: absolute;
                    content: "";
                    width: calc(100% + 6px);
                    right: -3px;
                    height: 3px;
                    bottom: -6px;
                    background-color: $white-100;
                    opacity: 0;
                    transition: all 400ms ease-in-out;
                }

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            &.ul-separator {
                position: relative;
                flex-grow: 0;

                li:not(:last-child) {
                    margin-right: 25px;
                }

                &::after {
                    position: absolute;
                    top: calc(25% - 1px);
                    right: 50%;
                    z-index: 9;
                    content: " ";
                    width: 2px;
                    height: 1.7rem;
                    background-color: $white-400;
                }
            }
        }


        .header__section-menu {
            margin-right: 4vw;
        }

        .header__section-menu .selected {
            transform: translateY(-6px);
            transition: all 200ms ease-out;

            a::before {
                transform: translateY(0);
                transition: all 300ms ease-in-out;
                opacity: 1;
            }

            a {
                color: $white-100;
            }
        }
    }
}

.header__nav-wrapper-fixed {
    display: none;
}

nav span:hover,
nav ul li:hover {
    opacity: 1;
    color: $white-000 !important;
    transition: all 200ms ease-in-out;
}

.header__translate-menu-collapsed,
.header__translate-menu {
    li {
        opacity: 0.4;
        cursor: pointer;
    }

    .selected {
        opacity: 1;
        transition: opacity 400ms ease-in-out;
    }
}

.header__menu-icon {
    position: relative;
    z-index: 10;
    stroke: white;
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    height: 2.8rem;
    width: 2.8rem;
    margin-right: 10px;
    transition: transform 300ms ease-out;
    cursor: pointer;

    & line:nth-child(1) {
        transform-origin: center;
        transition: transform 300ms ease-out;
    }

    & line:nth-child(2) {
        transform-origin: 5px 9px;
        transition: transform 300ms ease-out;
    }

    & line:nth-child(3) {
        transform-origin: 5px 15px;
        transition: transform 300ms ease-out;
    }

    &.open-menu {
        & line {
            transition: transform 300ms ease-out;
        }

        & line:nth-child(1) {
            transform: scale(0);
        }

        & line:nth-child(2) {
            transform: rotateZ(45deg);
        }

        & line:nth-child(3) {
            transform: rotateZ(-45deg);
        }

    }
}

.header__nav-collapsed {
    display: static;
}

.header__nav-modal {
    z-index: 9;
    position: absolute;
    display: flex;
    top: 76px;
    right: 0;
    background-color: $headerBG;
    width: 100%;
    min-width: 320px;
    height: calc(100vh - 75px);
    transform: translateX(100%);
    transition: transform 400ms ease;
    padding-bottom: 20%;
    justify-content: space-evenly;

    &.open-menu {
        transform: translateX(0);
        transition: transform 400ms ease;
    }

    .header__section-menu-collapsed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        flex-grow: 0;

        li {
            font-size: 3.3rem;
            margin-top: 1rem;

            &.selected {
                a span {
                    color: #b89d58;
                }
            }

            &:hover {
                a span {
                    color: #C0B69D !important;
                }
            }

        }
    }

    .header__translate-menu-collapsed {
        position: relative;
        width: min-content;
        height: min-content;
        margin-top: 6rem;

        li {
            font-size: 2.4rem;
        }

    }

}

.header__nav-fulled {
    display: none;
}

@media (min-width: 840px) {
    .header__nav-container {
        position: static;
        height: 110px;
    }

    .header__nav-collapsed {
        display: none;
    }

    .header__nav-fulled {
        display: flex;
    }

    .header__vika-logo {
        min-height: 60px;
    }

    .header__nav-wrapper {
        background-color: transparent;
        border-bottom: none;
        padding: 0;
        position: relative;
        transition: all 1s ease-in-out;
        width: auto;
    }

    .header__nav-wrapper-fixed {
        display: flex;
        background-color: $headerBG;
        border-bottom: 1px solid $white-500;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        z-index: 10;
        transition: transform 600ms ease-in-out;
        transform: translateY(-100%);

        .header__vika-logo {
            transform: scale(0.8);
        }

        .header__nav-container {
            position: static;
            max-width: 1100px;
            width: 90%;
            height: 75px;
        }

        &.show-fixed-nav {
            transform: translateY(0);
        }
    }
}


@media (min-width: 940px) {
    header {
        padding: 0 clamp(10px, 10%, 700px);
    }
}