.contacto__section {
    padding-top: 8rem;
    padding-bottom: 3rem;
}

.contacto__grid {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: 40px;
    margin: 3rem 0;
}

.contacto__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 100%;
    grid-row: 3 / 4;

    h3 {

        font-size: 2.3rem;
        margin-bottom: 1.5rem;

        span {
            color: $white-000;
        }
    }

    h3,
    .contacto__info-horarios,
    .contacto__info-encontranos {
        text-align: left;
        width: 100%;
        min-width: 240px;

        div {
            line-height: 2.5rem;
            font-size: 1.5rem;

            h4 {
                display: inline;
                font-weight: 800;
                margin-right: 20px;
                color: $white-100;
            }

        }

        div:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.loading-button {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        margin-left: 8px;
    }
}

.contacto__vika-logo img {
    margin-top: 4rem;
    margin-right: 0%;
    width: 15rem;
}

.contacto__form {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: none;
    margin-left: auto;
    grid-row: 1 / 2;
    height: 100%;

    div {
        height: min-content;

        &.w-100 {
            height: max-content;
            flex-grow: 1;
        }
    }

    .contacto__form-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    input {
        width: 100%;
        height: 3.7rem;
        background-color: #242133;
        box-shadow: 0px 8px 40px 0px rgb(0 0 0 / 41%);
        border: none;
        margin: 8px 0 0 0;
        padding: 6px 10px;
        border-radius: 12px;

        &:focus {
            outline: 1px solid rgb(90, 90, 90);
        }
    }

    input:nth-child(2n -1) {
        margin-right: 10px;
    }

    .w-50 {
        width: 47%;
    }

    .contacto__textarea-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 8px;
    }

    .contacto__textarea {
        width: 100%;
        border: none;
        padding: 6px 10px;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.103);
        box-shadow: 0px 8px 40px 0px rgb(0 0 0 / 41%);
        flex-grow: 2;

        &:focus {
            outline: 1px solid rgb(90, 90, 90);
        }
    }

    .contacto__form-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contacto__form-response {
        flex-grow: 1;
        text-align: center;
        color: green;
    }

    .contacto__form-response {
        p {
            flex-grow: 1;
            text-align: center;
        }
    }

    .contacto__succes-msg {
        color: #307f30;
    }

    .contacto__form-btn {
        background-color: rgba(255, 255, 255, 0.103);
        box-shadow: 0px 8px 40px 0px rgb(0 0 0 / 41%);
        margin: 8px 0;
        height: min-content;
        width: min-content;
        padding: 8px 16px;
        border: none;
        cursor: pointer;
        align-self: flex-end;

        &:hover {
            opacity: 70%;
        }
    }
}

.form-error {
    font-size: 1.3rem;
    color: #cd4242;
    position: relative;
    top: -1.3rem;
    left: 1rem;
    z-index: -1;
    opacity: 0;

    &.show {
        transform: translateY(1.3rem);
        opacity: 1;
        transition: all 300ms ease;
    }
}

.contacto__info-encontranos {
    margin-top: 40px;
}

@media (min-width:580px) {

    .contacto__info-container {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }

    .contacto__info-encontranos {
        margin-top: 0px;
    }

    .contacto__form {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        margin-bottom: 3rem;
    }

    .contacto__grid {
        grid-template-columns: 1fr 1fr;
    }

    .contacto__info-container {

        h3,
        .contacto__info-horarios,
        .contacto__info-encontranos {
            width: 50%;
        }

    }

    .contacto__vika-logo img {
        margin-right: 30%;
        margin-top: 0;
        width: 16rem;
    }

}

@media (min-width:850px) {
    .contacto__vika-logo img {
        margin-right: 0%;
        width: 18rem;
    }

    .contacto__form {
        max-width: 350px;
        grid-column: 3 / 4;
    }

    .contacto__grid {
        grid-template-columns: 1fr min-content 1fr;
        column-gap: 30px;
    }

    .contacto__info-container {
        flex-direction: column;
    }

    .contacto__info-container {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .contacto__info-encontranos {
        margin-top: 40px;
    }

    .contacto__info-container {

        h3,
        .contacto__info-horarios,
        .contacto__info-encontranos {
            width: 100%;
        }

    }

}