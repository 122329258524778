@keyframes timeBarAnim {
    from {
        height: 0;
        opacity: 0.2;
    }

    to {
        height: 100%;
        opacity: 0.9;
    }
}

@keyframes imgZoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.2);
    }
}

.carrousel__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
}

.carrousel_timebar {
    position: absolute;
    z-index: 8;
    bottom: 0;
    left: 0;
    height: 0;
    width: 5px;
    background-color: $white-100;

    &.show-bar {
        animation-name: timeBarAnim;
        animation-duration: 8.5s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
}

.carrousel_img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;


    &.zoom {
        animation-name: imgZoom;
        animation-duration: 8.5s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .carrousel__img {
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        opacity: 0;

        &.show-img {
            transition: opacity 1s ease-in-out;
            opacity: 1;
        }
    }
}

.carrousel__title {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 2rem;
    color: $white-100;
    width: 100%;
}

.carrousel__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 25%;
    width: 100%;

    // background-color: red;


    .carrousel__count {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        letter-spacing: 0.8rem;
        color: $white-400;
        width: 100%;

        span {
            font-size: 3rem;
            line-height: 3rem;
            color: $white-100;
        }
    }

    .carrousel__text-wrapper {
        overflow: hidden;
        background-color: transparent;
        width: 100%;
        transform: translateX(20%);
        display: flex;
        flex-direction: column;

        .carrousel__count,
        .carrousel__title,
        .carrousel__description,
        .carrousel_cta {
            transform: translateX(-100%);
        }
    }

    .carrousel__text-wrapper.show {
        transform: translateX(0);
        transition: transform 600ms ease-in-out;

        .show {
            transform: translateX(0);
            transition: transform 600ms ease-in-out;
        }
    }

    .carrousel__description {
        font-size: 1.8rem;
        margin-bottom: 20px;
    }
}

.carrousel_buttons-container {
    position: absolute;
    bottom: 15%;
}

.carrousel__button {
    top: 0;
    border: none;
    background-color: transparent;
    margin-right: 20px;
    min-width: 100px;
    overflow: hidden;
    cursor: pointer;

    p {
        font-size: 1.4rem;
        letter-spacing: 0.3rem;
        font-weight: 600;
    }

    &::after {
        content: " ";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: $white-400;
    }

    &:hover::after {
        opacity: 0.4;
        transition: opacity 200ms ease-in-out;
    }

}

.carrousel-previous {
    left: 0;
}

.carrousel-next {
    right: 0;
}

@media (min-width: 640px){
    .carrousel__info{
        width: 90%;
    }
}

@media (min-width: 915px) {

    .carrousel_buttons-container {
        position: static;
    }

    .carrousel__info {
        margin-bottom: 15%;
    }

    .carrousel__button {
        position: absolute;
        top: 0;
        border: none;
        background-color: transparent;
        margin-right: 0;
        height: 100%;
        width: 10%;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            transform: rotate(-90deg);
        }
    }

    .carrousel__title {
        font-size: 5rem;
        line-height: 5rem;
    }

    .carrousel-previous {
        left: 0;
    }

    .carrousel-next {
        right: 0;
    }

}


@media (min-width: 1160px) {
    .carrousel__title {
        font-size: 6rem;
        line-height: 6rem;
    }
}